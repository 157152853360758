<template>
  <div class="realTimeDetails" v-loading.fullscreen.lock="fullscreenLoading">
      <div class="table">
        <div class="tableDetails">
              <el-table
                :data="tableData"
                border
                :header-cell-style="{background:'#26CB78',color:'#fff'}"
                :class="overClass ? isFullscreen === '1' ? 'isStopClassAndIsFullscreen' : 'isStopClassAndNoFullscreen' : isFullscreen === '1' ? 'contStopClassAndIsFullscreen': 'contStopClassAndNoFullscreen'">
                <template v-for="col in cols" >
                  <el-table-column v-if="col.type==='index'" :type="col.type" :label="col.label"  align="center" :key="col.data">
                    <template slot-scope="scope">
                      <span>{{scope.$index + 1}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column v-if="col.type==='data'" :prop="col.data" :label="col.label" :key="col.data" align="center">
                      <template slot-scope="scope">
                        <span v-for='(item,index) in scope.row[col.data]' :key="index" style="display:inline-block;margin-right: 5px">
                          <img :src="item" alt="奖杯" class="trophyImg" v-if="Array.isArray(scope.row[col.data])">
                        </span>
                        <span v-if="!Array.isArray(scope.row[col.data])">{{scope.row[col.data]}}</span>
                    </template>
                  </el-table-column>
                </template>
             </el-table>
             <div v-if="!overClass">
                <div class="opera">
                  <div class="btn" @click="goBack">{{btnTitle}}</div> 
                </div>
             </div>
        </div>
      </div>
      <div class='bgc' v-if="overClass">
          <div class="signOutBtn" v-if="!isNextCourse">
              <div class="btn" @click="toSignOutClass">{{signOut}}</div> 
          </div>
          <div class="signOutAntNextCourse" v-if="isNextCourse">
              <div class="haoCourseTip">
                你今天还有课哦~ ({{courseStartTime}} - {{courseEndTime}})
              </div>
              <div class="operaBtn">
                  <span class="goSignIn" @click="continueClass">{{goToSignIn}}</span>
                  <span class="goSignOut" @click="toSignOutClass">{{signOut}}</span> 
              </div>
          </div>
          <img src="../../assets/image/stopCourseBGC.png" alt="" class="stopCourseBGC">
      </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { page } from './../../api/index.js';
import { Message } from 'element-ui';
export default {
  name: "realTimeDetails",
  
  data() {
    return {
        fullscreenLoading: false,
        btnTitle: '返回课堂',
        signOut: '退出账号',
        goToSignIn: '前往签到页',
        isFullscreen: localStorage.getItem('isFullscreen'),
        tableData: [],
        cols: [],
        isNextCourse: false,
        courseStartTime: '',
        courseEndTime: '',
        courseName: '',
        paikeId: '',
        overClass: this.$route.query.overClass ? 1 : 0,
    };
  },
  created() {
    if(this.overClass){
      this.getCoursePaikeId(1);
      this.getNextCourseInfo();
    }else{
      this.getCoursePaikeId('');
    }
    // this.getPaikeId().then(() => {
    //         this.getPageList();
    // }).catch(() => {
    //     this.getPaikeIdForCurrDayLatestClass().then(() => {
    //         this.getPageList();
    //     }).catch(() => {
    //        this.getPageList();
    //     });
    // })
  },
  methods: {
    ...mapActions(['logout']),
    async getCoursePaikeId(val){
        this.fullscreenLoading = true;
        await page.getCoursePaikeId(val, res => {
            console.log(res);
            if(res.status === 1) {
                if(res.data && res.data.courseName){
                    this.paikeId = res.data.scheduleId;
                    this.getPageList();
                }else{
                    Message.error('未查询到排课Id');
                    this.fullscreenLoading = false;
                }
            }else {
                Message.error(res.message);
                this.fullscreenLoading = false;
            }
            
        });
    },
    async getNextCourseInfo(){
        await page.getCoursePaikeId('', res => {
            if(res.status === 1) {
                if(res.data && res.data.courseName){
                    this.courseStartTime = res.data.courseStartTime;
                    this.courseEndTime = res.data.courseEndTime;
                    this.isNextCourse = true;
                    this.courseName = res.data.courseName;
                }else{
                    this.isNextCourse = false;
                }
            }else {
                Message.error(res.message);
                this.fullscreenLoading = false;
            }
        });
    },
        // getPaikeId(){
        //     this.fullscreenLoading = true;
        //     const that = this;
        //     return new Promise(function(resolve,reject){
        //         page.getCurrDayOnClass({}, res => {
        //             if(res.status === 1) {
        //                 if(res.data && res.data.courseName){
        //                     that.detailsFrom = '2';//正在上课
        //                     resolve()
        //                 }else{
        //                     that.detailsFrom = '1';// 未在上课
        //                     reject()
        //                 }   
        //             }else {
        //                 Message.error(res.message);
        //             }
        //             that.fullscreenLoading = false;
        //         });
        //     })
        // },
        // getPaikeIdForCurrDayLatestClass(){
        //     this.fullscreenLoading = true;
        //     const that = this;
        //     return new Promise(function(resolve,reject){
        //         page.getCurrDayLatestClassTime({}, res => {
        //             if(res.status === 1) {
        //                 that.detailsFrom = '1';
        //                 if(res.data && res.data.courseName){
        //                     that.courseStartTime = res.data.courseStartTime;
        //                     that.courseEndTime = res.data.courseEndTime;
        //                     that.isNextCourse = true;
        //                     that.courseName = res.data.courseName;
        //                     resolve()
        //                 }else{
        //                     that.isNextCourse = false;
        //                     reject()
        //                 }
        //             }else {
        //                 Message.error(res.message);
        //             }
        //             that.fullscreenLoading = false;
        //         });
        //     })
        // },
     getPageList(){
        // this.fullscreenLoading = true;
        page.getScoreList(this.paikeId, res => {
              if(res.status === 1) {
                  this.cols = res.data.headerVoList.map(item => ({
                    ...item,type: 'data'
                  }));
                  this.cols.unshift({data: 'index', label: '序号',type: 'index'})
                  this.tableData = res.data.valueList.map(item => item['map']);
                  this.fullscreenLoading = false;
              }else {
                  this.fullscreenLoading = false;
                  Message.error(res.message);
              }
          });
     },
     goBack(){
       this.$router.replace({name: 'attendClass'})
     },
    toSignOutClass(){
        this.logout();
    },
    continueClass(){
        this.$router.replace({
            path: "home",
        });
    },
  },
};
</script>

<style lang="less" scoped>
.realTimeDetails {
  height: 100%;
  width: 100%;
    .table{
        padding: 10px;
        .tableDetails{
            margin-bottom: 200px;
            padding: 40px 40px 10px 40px;
            border-radius: 30px;
            .star{
              color: #F8DA56;
              
            }
        }
    }
    .opera{
        position: fixed;
        right: 50px;
        bottom: 67px;
        width: 261px;
        height: 88px;
        background: #2CBD74;
        border-radius: 30px;
        text-align: right;
        cursor: pointer;
        z-index: 10;
        .btn{
            font-size: 30px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #FFFCFD;
            line-height: 88px;
            text-align: center;
        }
    }
    .tips{
        position: fixed;
        left: 50px;
        bottom: 67px;
        border-radius: 30px;
        text-align: right;
        cursor: pointer;
        z-index: 1000000;
        .words{
            font-size: 30px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #E10C0C;
            line-height: 88px;
            text-align: center;
        }
    }
}
/deep/.el-table--scrollable-y ::-webkit-scrollbar {
  display: none;
}
/deep/.el-table::before{
    height: 0;
  }
</style>
<style lang="scss" scoped>
  .tableDetails{
    height: calc(100vh - 200px)
  }
  .bgc{
    display: flex;
    border-radius: 30px;
    position: fixed;
    bottom: 0;
    height: 300px;
    z-index: 10;
    .signOutBtn{
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        margin:auto;
        width: 250px;
        height: 78px;
        background: #26CB78;
        border-radius: 44px;
        cursor: pointer;
        z-index: 1000000;
        .btn{
            font-size: 24px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #FFFCFD;
            line-height: 78px;
            text-align: center;
        }
    }
    .signOutAntNextCourse{
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        margin:auto;
        width: 100%;
        height: 200px;
        .haoCourseTip{
          text-align: center;
          font-size: 30px;
          font-family: Noto Sans S Chinese;
          font-weight: 400;
          color: #333333;
          line-height: 78px;
        }
        .operaBtn{
          text-align: center;
          margin-top: 40px;
          font-size: 24px;
          .goSignIn{
            display: inline-block;
            width: 250px;
            height: 78px;
            background: #26CB78;
            border-radius: 44px;
            cursor: pointer;
            z-index: 1000000;
            text-align: center;
            font-size: 30px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #fff;
            line-height: 78px;
            margin-right: 80px;
          }
          .goSignOut{
            display: inline-block;
            width: 250px;
            height: 78px;
            background: #DC5310;
            border-radius: 44px;
            cursor: pointer;
            z-index: 1000000;
            text-align: center;
            font-size: 30px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #fff;
            line-height: 78px;
          }
        }
    }
    .stopCourseBGC{
      width: 100%;
    }
  }
  .trophyImg{
    width: 15px;
    height: 15px;
  }
  img {
        image-rendering:-moz-crisp-edges;
        image-rendering:-o-crisp-edges;
        image-rendering:-webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode:nearest-neighbor;
  }
  .isStopClassAndIsFullscreen{
    max-height: 540px;
    width: 100%;
    overflow-y: scroll;
  }
  .isStopClassAndNoFullscreen{
    max-height: 440px;
    width: 100%;
    overflow-y: scroll;
  }
  .contStopClassAndIsFullscreen{
    max-height: 700px;
    width: 100%;
    overflow-y: scroll;
  }
  .contStopClassAndNoFullscreen{
    max-height: 600px;
    width: 100%;
    overflow-y: scroll;
  }
  
</style>
